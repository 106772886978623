import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import Amount from "../../formatters/Amount";
import Duration from "../../formatters/Duration";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";

interface TariffListRouteProps {}

const TariffListRoute: React.FC<TariffListRouteProps> = () => {
    const [tariffArray, setTariffArray] = useState<JUHUU.Tariff.Object[]>([]);
    const juhuu = useJUHUU();
    const navigate = useNavigate();
    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.tariffs.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setTariffArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const createTariff = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.tariffs.create({
            propertyId: property?.id,
            name: property?.name,
        });

        if (query.ok === false) {
            return;
        }

        navigate("./" + query.data.tariff.id);
    }, [property, juhuu, navigate]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Tariffs</Heading>
                <Button onClick={createTariff}>Create</Button>
            </div>
            <Text className="mb-8">
                Tariffs allow you to set prices for the services you offer.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Duration</TableHeader>
                        <TableHeader>Maximum amount</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tariffArray.map((tariff) => {
                        return (
                            <TableRow key={tariff.id} href={"./" + tariff.id}>
                                <TableCell>{tariff.name.en} </TableCell>
                                <TableCell>
                                    <Duration
                                        seconds={tariff.duration}
                                        display="duration"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Amount
                                        amount={juhuu.tariffs.calculateMaximumAmount(
                                            tariff,
                                        )}
                                        currencyCode={tariff.currencyCode}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default TariffListRoute;
