import { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Divider } from "../../components/divider";
import { useParams } from "react-router-dom";

interface ProductRouteProps {}

const ProductRoute: React.FC<ProductRouteProps> = () => {
    const juhuu = useJUHUU();
    const [product, setProduct] = useState<JUHUU.Product.Object>();
    const { productId } = useParams<{ productId: string }>();

    const handleRefresh = useCallback(async () => {
        if (productId === undefined) {
            return;
        }

        const query = await juhuu.products.retrieve({
            productId: productId,
        });

        if (query.ok === false) {
            return;
        }

        setProduct(query.data.product);
    }, [productId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>{product?.name}</Heading>
                </div>

                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-1">
                        <Text>{product?.id}</Text>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="mt-4 "></div>
        </>
    );
};

export default ProductRoute;
/*
amountAuthorized: number; // amount of the article that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the article that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
