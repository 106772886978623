import React, { FC } from "react";

interface AnimatedGradientProps {
    style: React.CSSProperties;
    clipPath?: string | undefined;
}

const AnimatedGradient: FC<AnimatedGradientProps> = ({ style, clipPath }) => {
    // Default gradient colors and duration
    const gradientColors = "#9a73cc, #c990a3, #d7a0cc, #7993d1, #a9d3f8";
    const animationDuration = 5;

    // Styles for the gradient and the animation
    const gradientStyle: React.CSSProperties = {
        width: "100vw",
        height: "75vh",
        background: `linear-gradient(45deg, ${gradientColors})`,
        backgroundSize: "400% 400%",
        animation: `gradient-animation ${animationDuration}s ease infinite`, // Refers to global keyframes
        clipPath: clipPath, // Clip-path creates the slanted bottom
    };

    return <div style={{ ...style, ...gradientStyle }}></div>;
};

export default AnimatedGradient;
