import React from "react";
import { useEnvironment } from "./context/EnvironmentContext";
import JuhuuProvider from "./context/JuhuuContext";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./context/UserContext";
import PropertyProvider from "./context/PropertyContext";
import Credentials from "./Credentials";
import App from "./App";
import { toast } from "react-toastify";

function Providers() {
    const { environment } = useEnvironment();

    return (
        <>
            <JuhuuProvider
                config={{
                    clientVersion: "1.0.300",
                    environment: environment,
                    getAccessToken: async () => {
                        return localStorage.getItem("accessToken");
                    },
                    getRefreshToken: async () => {
                        return localStorage.getItem("refreshToken");
                    },
                    onException: async (response) => {
                        switch (response.status) {
                            case 400: {
                                toast.error(
                                    "Invalid input. Please check your input and try again.",
                                );
                                break;
                            }
                            case 401: {
                                toast.error(
                                    "You are not authorized to perform this action.",
                                );

                                break;
                            }
                            case 403: {
                                toast.error(
                                    "Your login has expired. Please log in again.",
                                );
                                break;
                            }
                            case 404: {
                                toast.error(
                                    "The requested resource was not found.",
                                );
                                break;
                            }

                            case 503: {
                                toast.error(
                                    "Due to maintenance the servers are currently not reachable. Please try again later.",
                                );
                                break;
                            }

                            default:
                                break;
                        }
                        return "abort";
                    },
                    setAccessToken: async (accessToken) => {
                        localStorage.setItem("accessToken", accessToken);
                    },
                    setRefreshToken: async (refreshToken) => {
                        localStorage.setItem("refreshToken", refreshToken);
                    },
                }}
            >
                <BrowserRouter basename="/">
                    <UserProvider>
                        <PropertyProvider>
                            <Credentials />
                            <App />
                        </PropertyProvider>
                    </UserProvider>
                </BrowserRouter>
            </JuhuuProvider>
        </>
    );
}

export default Providers;
