import { useCallback, useEffect, useState } from "react";

import { JUHUU } from "@juhuu/sdk-ts";
import { Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Divider } from "../../components/divider";
import Timestamp from "../../formatters/Timestamp";
import { Link } from "../../components/link";
import Amount from "../../formatters/Amount";
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
} from "../../components/description-list";
import { useJUHUU } from "../../context/JuhuuContext";

interface PaymentProps {
    payment?: JUHUU.Payment.Object | null;
    paymentId?: string | null;
    visible?: boolean;
}

const Payment: React.FC<PaymentProps> = ({
    payment: paymentExternal = null,
    paymentId: paymentIdExternal = null,
    visible = true,
}) => {
    const [payment, setPayment] = useState<JUHUU.Payment.Object | null>(null);
    const juhuu = useJUHUU();
    const { property } = useProperty();

    const handleRefresh = useCallback(async () => {
        if (paymentExternal !== null) {
            console.log("1");
            setPayment(paymentExternal);
            return;
        }

        if (paymentIdExternal === null) {
            console.log("3");
            return;
        }

        console.log("4");

        const query = await juhuu.payments.retrieve({
            paymentId: paymentIdExternal,
        });

        if (query.ok === false) {
            return;
        }

        setPayment(query.data.payment);
    }, [paymentExternal, paymentIdExternal, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    if (visible === false) {
        return null;
    }

    return (
        <>
            <div className="w-full flex flex-row justify-start items-center gap-4">
                <Link
                    href={
                        "/properties/" +
                        property?.id +
                        "/payments/" +
                        payment?.id
                    }
                    className="flex-none"
                >
                    <Subheading>Payment</Subheading>
                </Link>
            </div>
            <Divider />
            <DescriptionList>
                <DescriptionTerm>Created</DescriptionTerm>
                <DescriptionDetails>
                    <Timestamp timestamp={payment?.createdAt} />
                </DescriptionDetails>

                <DescriptionTerm>Amount authorized</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountAuthorized}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>

                <DescriptionTerm>Amount to payout</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountToPayout}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>
            </DescriptionList>
        </>
    );
};

export default Payment;
