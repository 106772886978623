import React, { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Avatar } from "../../components/avatar";
import { Text } from "../../components/text";
import { Badge } from "../../components/badge";
import { useJUHUU } from "../../context/JuhuuContext";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

interface PropertyArrayProps {
    propertyListParams: JUHUU.Property.List.Params;
}

const PropertyArrayComponent: React.FC<PropertyArrayProps> = ({
    propertyListParams,
}) => {
    const [propertyArray, setPropertyArray] = useState<JUHUU.Property.Object[]>(
        [],
    );
    const juhuu = useJUHUU();
    const { user } = useUser();
    const navigate = useNavigate();

    const handleRefresh = useCallback(async () => {
        const query = await juhuu.properties.list(propertyListParams);

        if (query.ok === false) {
            return;
        }

        setPropertyArray(query.data);
    }, [propertyListParams, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <div className="flex flex-row gap-4 w-full flex-wrap justify-center">
            {propertyArray.map((property) => {
                const userIsOwner = property.ownerUserIdArray?.includes(
                    user?.id ?? "123",
                );
                return (
                    <div
                        key={property.id}
                        className={clsx(
                            "w-full sm:w-1/3 md:w-1/4 lg:w-1/6 rounded-xl p-4 flex flex-col justify-center items-center gap-4 border",
                            userIsOwner ? "cursor-pointer" : "",
                        )}
                        style={{
                            backgroundColor: "white",
                            // property.colorScheme.light.backgroundNavigation,
                        }}
                        onClick={() =>
                            userIsOwner
                                ? navigate("/properties/" + property.id)
                                : undefined
                        }
                    >
                        <Avatar
                            src={property.iconLight}
                            initials={
                                property.iconLight === null
                                    ? property.name.slice(0, 2)
                                    : undefined
                            }
                            className="size-12"
                        />
                        <Text className="text-center">{property.name}</Text>
                        {userIsOwner === true && (
                            <Badge color="lime">You are owner</Badge>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default PropertyArrayComponent;
