import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { Avatar } from "../../components/avatar";
import Utilization from "../../formatters/BadgeUtilization";
import LocationType from "./LocationType";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";
import { Field, Label } from "../../components/fieldset";
import { Listbox, ListboxLabel, ListboxOption } from "../../components/listbox";
import { useNavigate } from "react-router-dom";

interface LocationListRouteProps {}

const LocationListRoute: React.FC<LocationListRouteProps> = () => {
    const [locationArray, setLocationArray] = useState<JUHUU.Location.Object[]>(
        [],
    );
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const navigate = useNavigate();
    const [selectedLocationType, setSelectedLocationType] = useState<
        "rentableDeviceGroup" | "rentableDevice" | "useableDevice"
    >("rentableDeviceGroup");
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.locations.list({
            propertyId: property.id,
            rentableDeviceGroupLocationId: "null",
        });

        if (query.ok === false) {
            return;
        }
        setLocationArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    let [isOpen, setIsOpen] = useState(false);

    const createLocation = useCallback(
        async (
            deviceTemplateId:
                | "rentableDeviceGroup"
                | "rentableDevice"
                | "useableDevice",
        ) => {
            if (property?.id === undefined) {
                return;
            }

            const query = await juhuu.locations.create({
                propertyId: property?.id,
                name: property?.name,
                type: deviceTemplateId,
            });

            if (query.ok === false) {
                return;
            }

            navigate("./" + query.data.location.id);
        },
        [property, juhuu, navigate],
    );

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Locations</Heading>
                <Button onClick={() => setIsOpen(true)}>Create</Button>
            </div>
            <Dialog open={isOpen} onClose={setIsOpen}>
                <DialogTitle>Create Location</DialogTitle>
                <DialogDescription>Dialog Description</DialogDescription>
                <DialogBody>
                    <Field>
                        <Label>Location Type</Label>
                        <Listbox
                            name="type"
                            value={selectedLocationType}
                            onChange={(value) => {
                                setSelectedLocationType(value);
                            }}
                        >
                            <ListboxOption value="rentableDeviceGroup">
                                <ListboxLabel>rentable Group</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="rentableDevice">
                                <ListboxLabel>rentable Single</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="useableDevice">
                                <ListboxLabel>useable Single</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            createLocation(selectedLocationType);
                        }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Text className="mb-8">This list contains your locations.</Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Utilization</TableHeader>
                        <TableHeader>Type</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locationArray.map((location) => {
                        return (
                            <TableRow
                                key={location.id}
                                href={"./" + location.id}
                            >
                                {/*href={"./" + location.id}*/}
                                <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                                    <Avatar
                                        src={location.iconLight}
                                        className="size-12"
                                    />
                                    <Text>{location.name}</Text>
                                </TableCell>
                                <TableCell>
                                    <Utilization location={location} />
                                </TableCell>
                                <TableCell>
                                    <LocationType type={location.type} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default LocationListRoute;
