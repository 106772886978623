import React from "react";
import clsx from "clsx";
import { Link } from "./link";

export function Text({
    className,
    ...props
}: React.ComponentPropsWithoutRef<"p">) {
    return (
        <p
            data-slot="text"
            {...props}
            className={clsx(
                className,
                "text-md/6 text-zinc-500 sm:text-md/6 dark:text-zinc-400",
            )}
        />
    );
}

export function TextLink({
    className,
    isVioletParent = false,
    children,
    href,
    ...props
}: React.ComponentPropsWithoutRef<typeof Link> & { isVioletParent?: boolean }) {
    return (
        <div className="relative inline-block">
            <Link
                {...props}
                href={href}
                className={clsx(
                    className,
                    "text-violet-500 hover:text-violet-700 font-bold decoration-zinc-950/50 data-[hover]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white",
                )}
            >
                {React.Children.map(children, (child) =>
                    React.isValidElement(child)
                        ? React.cloneElement(
                              child as React.ReactElement<{
                                  isVioletParent?: boolean;
                              }>,
                              { isVioletParent },
                          )
                        : child,
                )}
            </Link>
        </div>
    );
}

export function Strong({
    className,
    isVioletParent = false,
    ...props
}: React.ComponentPropsWithoutRef<"strong"> & { isVioletParent?: boolean }) {
    // console.log("isVioletParent Strong", isVioletParent);
    return (
        <strong
            {...props}
            className={clsx(
                className,
                "font-medium",
                // Conditionally apply color based on the parent's text color
                isVioletParent
                    ? ""
                    : "font-medium text-zinc-950 dark:text-white",
            )}
        />
    );
}

export function Code({
    className,
    ...props
}: React.ComponentPropsWithoutRef<"code">) {
    return (
        <code
            {...props}
            className={clsx(
                className,
                "rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 text-sm font-medium text-zinc-950 sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white",
            )}
        />
    );
}
