import { useCallback, useEffect, useState } from "react";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
} from "../../components/description-list";
import Duration from "../../formatters/Duration";
import Amount from "../../formatters/Amount";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
} from "recharts"; // Recharts for the graph
import { Button } from "../../components/button";
import { useJUHUU } from "../../context/JuhuuContext";

interface TariffRouteProps {}

const TariffRoute: React.FC<TariffRouteProps> = () => {
    const [tariff, setTariff] = useState<JUHUU.Tariff.Object | null>(null);
    const [pricingData, setPricingData] = useState<any[]>([]);
    const { tariffId } = useParams<{ tariffId: string }>();
    const juhuu = useJUHUU();

    const formatTime = (timeInSeconds: number) => {
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;
        const secondsInMonth = 2592000; // Approximate (30 days)
        const secondsInYear = 31536000; // Approximate (365 days)

        if (timeInSeconds < secondsInMinute) {
            return `${timeInSeconds} sec`; // Display in seconds
        } else if (timeInSeconds < secondsInHour) {
            return `${(timeInSeconds / secondsInMinute).toFixed(0)} min`; // Display in minutes
        } else if (timeInSeconds < secondsInDay) {
            return `${(timeInSeconds / secondsInHour).toFixed(0)} hr`; // Display in hours
        } else if (timeInSeconds < secondsInWeek) {
            return `${(timeInSeconds / secondsInDay).toFixed(0)} day`; // Display in days
        } else if (timeInSeconds < secondsInMonth) {
            return `${(timeInSeconds / secondsInWeek).toFixed(0)} wk`; // Display in weeks
        } else if (timeInSeconds < secondsInYear) {
            return `${(timeInSeconds / secondsInMonth).toFixed(0)} mo`; // Display in months
        } else {
            return `${(timeInSeconds / secondsInYear).toFixed(1)} yr`; // Display in years
        }
    };

    const getStepSize = (duration: number) => {
        if (duration <= 3600) {
            // For durations up to 1 hour, sample every second
            return 1;
        } else if (duration <= 86400) {
            // For durations up to 1 day, sample every minute (60 seconds)
            return 60;
        } else if (duration <= 2592000) {
            // For durations up to 30 days, sample every 10 minutes (600 seconds)
            return 600;
        } else {
            // For longer durations, sample every hour (3600 seconds)
            return 3600;
        }
    };

    const handleRefresh = useCallback(async () => {
        if (tariffId === undefined) {
            return;
        }

        const query = await juhuu.tariffs.retrieve({
            tariffId: tariffId,
        });

        if (query.ok === false) {
            return;
        }

        const fetchedTariff = query.data.tariff;
        setTariff(fetchedTariff);

        const data = [];

        const stepSize = getStepSize(fetchedTariff.duration); // Determine the step size dynamically

        // Iterate over the duration in larger steps
        for (
            let rentTime = 0;
            rentTime <= fetchedTariff.duration;
            rentTime += stepSize
        ) {
            const baseAmount = juhuu.tariffs.calculateAmount(
                fetchedTariff,
                rentTime,
            );
            const serviceFee =
                baseAmount * (fetchedTariff.serviceFeePercentage / 100);
            const totalAmount =
                baseAmount + Math.max(serviceFee, fetchedTariff.serviceFeeMin);

            data.push({
                timeInSeconds: rentTime,
                revenueWithoutServiceFee: baseAmount,
                totalRevenueWithServiceFee: totalAmount,
            });
        }

        setPricingData(data);
    }, [tariffId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>{tariff?.name.en}</Heading>
                    <Text>Tariff</Text>
                </div>

                <div className="flex gap-4 items-center">
                    <Text>{tariffId}</Text>
                    <Button href="./edit">Edit</Button>
                </div>
            </div>
            <Divider />
            <div className="flex justify-start flex-row items-center py-4">
                <div className="flex justfy-start flex-col items-start pr-8">
                    <Text>Maximum price</Text>
                    <Text>
                        {tariff !== null && (
                            <Amount
                                amount={juhuu.tariffs.calculateMaximumAmount(
                                    tariff,
                                )}
                                currencyCode={tariff.currencyCode}
                            />
                        )}
                    </Text>
                </div>
                <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
                    <Text>Round to midnight</Text>
                    <Text>
                        {tariff?.roundToMidnight.toString().toUpperCase()}{" "}
                    </Text>
                </div>
                <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
                    <Text>Manual termination</Text>
                    <Text>
                        {tariff?.manualTerminationEnabled
                            .toString()
                            .toUpperCase()}{" "}
                    </Text>
                </div>
            </div>

            {/* Chart Section */}
            <Subheading className="mt-8">Price over time</Subheading>
            <Divider />
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={pricingData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="timeInSeconds"
                        scale={"time"}
                        tickCount={100} // Reduces the number of ticks for readability
                        interval="preserveStartEnd" // Adjusts the tick marks for better distribution
                        domain={[0, tariff?.duration as number]} // Set the domain to ensure X axis covers the whole duration
                        tickFormatter={(timeInSeconds) =>
                            formatTime(timeInSeconds)
                        } // Custom tick formatter
                    />
                    <YAxis
                        tickFormatter={(value) =>
                            new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: tariff?.currencyCode,
                            }).format(value / 100)
                        }
                    />
                    {/* <Line
            type="monotone"
            dataKey="revenueWithoutServiceFee"
            stroke="#6e17fa" // Purple line for base amount
            activeDot={{ r: 8 }}
          /> */}
                    <Line
                        type="linear"
                        dataKey="totalRevenueWithServiceFee"
                        stroke="#6e17fa" // Grey line for total amount with service fee
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>

            <Subheading className="mt-8">General</Subheading>
            <Divider />
            <DescriptionList>
                <DescriptionTerm>Maximum duration</DescriptionTerm>
                <DescriptionDetails>
                    <Duration
                        seconds={tariff?.duration ?? 0}
                        display="duration"
                    />
                </DescriptionDetails>
                <DescriptionTerm>Manual termination</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.manualTerminationEnabled.toString().toUpperCase()}
                </DescriptionDetails>
                <DescriptionTerm>Round to midnight</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.roundToMidnight.toString().toUpperCase()}
                </DescriptionDetails>
                <DescriptionTerm>Currency code</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.currencyCode.toUpperCase()}
                </DescriptionDetails>
                <DescriptionTerm>Sales tax</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.salesTaxPercentage}%
                </DescriptionDetails>
            </DescriptionList>

            <Subheading className="mt-8">Pricing details</Subheading>
            <Divider />
            <DescriptionList>
                {tariff?.amount.map((amount, index) => {
                    return (
                        <>
                            <DescriptionTerm>
                                {index + 1}.{" "}
                                <Duration
                                    seconds={tariff?.interval ?? 0}
                                    display="duration"
                                />
                            </DescriptionTerm>
                            <DescriptionDetails>
                                <Amount
                                    amount={amount}
                                    currencyCode={tariff?.currencyCode}
                                />
                            </DescriptionDetails>
                        </>
                    );
                })}
                <DescriptionTerm>Afterwards</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={tariff?.continue}
                        currencyCode={tariff?.currencyCode}
                    />
                </DescriptionDetails>
                <DescriptionTerm>Interval</DescriptionTerm>
                <DescriptionDetails>
                    <Duration
                        seconds={tariff?.interval ?? 0}
                        display="duration"
                    />
                </DescriptionDetails>
            </DescriptionList>

            <Subheading className="mt-8">Reminder</Subheading>
            <Divider />
            <DescriptionList>
                <DescriptionTerm>Enabled</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.reminderEnabled.toString().toUpperCase()}
                </DescriptionDetails>
                <DescriptionTerm>Reminder percentage</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.reminderPercentage}%
                </DescriptionDetails>
            </DescriptionList>

            <Subheading className="mt-8">Service fee</Subheading>
            <Divider />
            <DescriptionList>
                <DescriptionTerm>Service fee</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.serviceFeePercentage}% (minimum{" "}
                    <Amount
                        amount={tariff?.serviceFeeMin}
                        currencyCode={tariff?.currencyCode}
                    />{" "}
                    and maximum{" "}
                    <Amount
                        amount={tariff?.serviceFeeMax}
                        currencyCode={tariff?.currencyCode}
                    />
                    )
                </DescriptionDetails>
            </DescriptionList>

            <Subheading className="mt-8">Automatic renewal</Subheading>
            <Divider />
            <DescriptionList>
                <DescriptionTerm>Manual enabled</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.autoRenewManualEnabled.toString().toUpperCase()}
                </DescriptionDetails>
                <DescriptionTerm>Mode</DescriptionTerm>
                <DescriptionDetails>
                    {tariff?.autoRenewMode.toUpperCase()}
                </DescriptionDetails>
            </DescriptionList>
        </>
    );
};

export default TariffRoute;
