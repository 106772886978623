import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";
import { Field, Label } from "../../components/fieldset";
import { Listbox, ListboxLabel, ListboxOption } from "../../components/listbox";

interface ConnectorListRouteProps {}

const ConnectorListRoute: React.FC<ConnectorListRouteProps> = () => {
    const [connectorArray, setConnectorArray] = useState<
        JUHUU.Connector.Object[]
    >([]);
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const navigate = useNavigate();
    const [selectedConnectorType, setSelectedConnectorType] = useState<
        "mqtt" | "boldLock"
    >("mqtt");
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.connectors.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setConnectorArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    let [isOpen, setIsOpen] = useState(false);

    const createConnector = useCallback(
        async (deviceTemplateId: "mqtt" | "boldLock") => {
            if (property?.id === undefined) {
                return;
            }

            const query = await juhuu.connectors.create({
                propertyId: property?.id,
                type: deviceTemplateId,
            });

            if (query.ok === false) {
                return;
            }
            navigate("./" + query.data.connector.id);
        },
        [property, juhuu, navigate],
    );

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Connectors</Heading>
                <Button onClick={() => setIsOpen(true)}>Create</Button>
            </div>
            <Dialog open={isOpen} onClose={setIsOpen}>
                <DialogTitle>Create Location</DialogTitle>
                <DialogDescription>Dialog Description</DialogDescription>
                <DialogBody>
                    <Field>
                        <Label>Location Type</Label>
                        <Listbox
                            name="type"
                            value={selectedConnectorType}
                            onChange={(value) => {
                                setSelectedConnectorType(value);
                            }}
                        >
                            <ListboxOption value="mqtt">
                                <ListboxLabel>MQTT</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            createConnector(selectedConnectorType);
                        }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Text className="mb-8">
                A connector representes the connection between a real-world
                device and JUHUU.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {connectorArray.map((connector) => {
                        return (
                            <TableRow
                                key={connector.id}
                                href={"./" + connector.id}
                            >
                                <TableCell>{connector.name} </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default ConnectorListRoute;
