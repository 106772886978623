import React, { forwardRef } from "react";

interface ContainerProps {
    children?: React.ReactNode;
    className?: string | undefined;
    visible?: boolean;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
    ({ children, className, visible = true }, ref) => {
        if (visible === false) {
            return null;
        }
        return (
            <div ref={ref} className={`w-full relative ${className}`}>
                <div className="relative mx-auto max-w-6xl px-4 z-20">
                    {children}
                </div>
            </div>
        );
    },
);

Container.displayName = "Container";

export default Container;
