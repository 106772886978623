import React, { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import ChatArray from "../routes/chats/ChatArray";
import { useUser } from "../context/UserContext";
import {
    ArrowLeftCircleIcon,
    PaperAirplaneIcon,
} from "@heroicons/react/16/solid";
import { Heading } from "./heading";
import { Input } from "./input";
import { useJUHUU } from "../context/JuhuuContext";
import { Button } from "./button";
import ChatMessage from "../routes/chatMessages/ChatMessage";
import { useProperty } from "../context/PropertyContext";

interface BuddyDialogProps {}

const Buddy: React.FC<BuddyDialogProps> = () => {
    const { user } = useUser();
    const { property } = useProperty();

    const [selectedChat, setSelectedChat] = useState<JUHUU.Chat.Object | null>(
        null,
    );
    const juhuu = useJUHUU();
    const [message, setMessage] = useState<string>("");

    const [chatMessageArray, setChatMessageArray] = useState<
        JUHUU.ChatMessage.Object[]
    >([]);

    const handleSetChatMessage = useCallback(
        (newChatMessage: JUHUU.ChatMessage.Object) => {
            // update chat message array
            setChatMessageArray((prevChatMessageArray) => {
                const index = prevChatMessageArray.findIndex(
                    (chatMessage) => newChatMessage.id === chatMessage.id,
                );

                if (index === -1) {
                    return prevChatMessageArray;
                }

                const newChatMessageArray = [...prevChatMessageArray];
                newChatMessageArray[index] = newChatMessage;

                return newChatMessageArray;
            });
        },
        [],
    );

    const handleListChatMessageArray = useCallback(async () => {
        if (selectedChat === null) {
            return;
        }

        const query = await juhuu.chatMessages.list(
            {
                chatId: selectedChat.id,
            },
            {
                limit: 10,
            },
        );

        if (query.ok === false) {
            return;
        }

        setChatMessageArray(query.data.chatMessageArray.reverse());
    }, [selectedChat, juhuu]);

    useEffect(() => {
        handleListChatMessageArray();
    }, [handleListChatMessageArray]);

    async function handleCreateChat() {
        if (user === null) {
            return;
        }

        const query = await juhuu.chats.create({
            userId: user.id,
        });

        if (query.ok === false) {
            return;
        }

        setSelectedChat(query.data.chat);
    }

    const handleGenerateTitle = useCallback(async () => {
        if (selectedChat === null) {
            return;
        }

        if (selectedChat.title !== null) {
            return;
        }

        if (chatMessageArray.length < 3) {
            return;
        }

        const chat = await juhuu.chats.generateTitle({
            chatId: selectedChat.id,
        });

        if (chat.ok === false) {
            return;
        }

        setSelectedChat(chat.data.chat);

        return chat.data.chat;
    }, [selectedChat, juhuu, chatMessageArray]);

    const handleCreateMessage = useCallback(async () => {
        if (selectedChat === null || user === null) {
            return;
        }

        const messageCreateQuery = await juhuu.chatMessages.create({
            chatId: selectedChat.id,
            message: message,
            userId: user.id,
            context: {
                ownerPropertyId: property?.id,
                userGroup: user.group,
                userName: user.name ?? undefined,
                frontend: "dashboard",
                platform: "web",
            },
        });

        if (messageCreateQuery.ok === false) {
            return;
        }

        setChatMessageArray([
            ...chatMessageArray,
            messageCreateQuery.data.chatMessage,
        ]);

        setMessage("");

        const completeChatQuery = await juhuu.chats.complete({
            chatId: selectedChat.id,
        });

        if (completeChatQuery.ok === false) {
            return;
        }

        handleGenerateTitle();

        setSelectedChat(completeChatQuery.data.chat);
        setChatMessageArray(completeChatQuery.data.chatMessageArray.reverse());
    }, [
        chatMessageArray,
        juhuu,
        message,
        selectedChat,
        user,
        property,
        handleGenerateTitle,
    ]);

    return (
        <>
            <div className="h-screen flex flex-col overflow-hidden p-2 pl-0">
                {selectedChat !== null && (
                    <div className="flex flex-row justify-start items-start gap-2 mb-2">
                        <div className="w-6 h-6 mt-1">
                            <ArrowLeftCircleIcon
                                className="w-6 h-6 text-zinc-300 cursor-pointer"
                                onClick={() => {
                                    setSelectedChat(null);
                                    setChatMessageArray([]);
                                }}
                            />
                        </div>

                        <Heading>
                            {selectedChat.title === null
                                ? "New chat"
                                : selectedChat.title}
                        </Heading>
                    </div>
                )}
                <div className="flex flex-grow flex-col justify-start overflow-scroll rounded-xl">
                    {selectedChat === null ? (
                        <ChatArray
                            onSelectedChat={setSelectedChat}
                            chatListParams={{
                                userId: user?.id,
                            }}
                        />
                    ) : (
                        <>
                            {chatMessageArray.map((chatMessage) => {
                                return (
                                    <ChatMessage
                                        chatMessage={chatMessage}
                                        setChatMessage={handleSetChatMessage}
                                    />
                                );
                            })}
                        </>
                    )}
                </div>
                {selectedChat === null ? (
                    <div>
                        <Button
                            onClick={() => handleCreateChat()}
                            className="w-full"
                        >
                            Create new chat
                        </Button>
                    </div>
                ) : (
                    <div className="flex items-center w-full gap-4 mt-4">
                        <Input
                            placeholder="Ask JUHUU Buddy anything..."
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <PaperAirplaneIcon
                            className="h-6 cursor-pointer"
                            onClick={() => handleCreateMessage()}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Buddy;
