import React from "react";
import { Heading } from "../../components/heading";
import { Text } from "../../components/text";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";
import PropertyArrayComponent from "./PropertyArray";
import Container from "../../components/container";

interface PropertyListRouteProps {}

const PropertyListRoute: React.FC<PropertyListRouteProps> = () => {
    const naivgate = useNavigate();

    return (
        <>
            <Container className="mt-16 mb-16">
                <div className="flex w-full flex-wrap items-end justify-between gap-4">
                    <Heading>Registered operators at JUHUU</Heading>

                    <div className="flex gap-4">
                        <Button onClick={() => naivgate("/")}>
                            Become an operator
                        </Button>
                    </div>
                </div>
                <Text className="mb-16">
                    Become an operator at JUHUU now or select your company from
                    the list.
                </Text>
                <PropertyArrayComponent propertyListParams={{}} />
            </Container>
        </>
    );
};

export default PropertyListRoute;
