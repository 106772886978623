import React, { useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Button } from "./button";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "./dialog";
import { Text } from "./text";

interface ColorPickerProps {
    setColor: (color: string) => void;
    color: string;
    disabled?: boolean;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
    setColor,
    color,
    disabled = false,
}) => {
    const [popupOpen, setPopupOpen] = useState(false);

    const getTextColor = (): string => {
        // Convert hex color to RGB
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);

        // Calculate relative luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Return black for light colors and white for dark colors
        return luminance > 0.5 ? "#000000" : "#ffffff";
    };

    return (
        <>
            <div className="flex flex-row gap-4 mt-2">
                <div
                    onClick={() => setPopupOpen(true)}
                    className="flex-grow border rounded-lg flex justify-start items-center flex-row px-2 cursor-pointer"
                    style={{
                        backgroundColor: color,
                        height: "40px",
                    }}
                >
                    <Text
                        style={{
                            color: getTextColor(),
                        }}
                    >
                        {color.toUpperCase()}
                    </Text>
                </div>
                <Button onClick={() => setPopupOpen(true)} disabled={disabled}>
                    Change color
                </Button>
            </div>
            <Dialog open={popupOpen === true} onClose={setPopupOpen}>
                <DialogTitle>Adjust the color</DialogTitle>
                <DialogDescription>
                    Pick a color from the pallet or type in a specific hex
                    value.
                </DialogDescription>
                <DialogBody>
                    <div className="grid grid-cols-2 gap-4 h-32">
                        <HexColorPicker
                            color={color}
                            onChange={setColor}
                            style={{ width: "100%", height: "100%" }}
                        />
                        <div className="w-full h-full flex flex-col gap-4">
                            <div
                                style={{ backgroundColor: color }}
                                className="w-full flex-grow rounded-lg"
                            />
                            <HexColorInput
                                color={color}
                                onChange={setColor}
                                prefixed={true}
                                className="border p-2 rounded-lg"
                            />
                        </div>
                    </div>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => setPopupOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ColorPicker;
