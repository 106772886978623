import { useCallback, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate } from "react-router-dom";
import { Select } from "../../components/select";
import { ColorScheme, CountryCode } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";
import ColorPicker from "../../components/color-picker";
import { TextLink } from "../../components/text";
import { Badge } from "../../components/badge";

interface PropertyEditRouteProps {}

const PropertyEditRoute: React.FC<PropertyEditRouteProps> = () => {
    const navigate = useNavigate();
    const { property, setProperty } = useProperty();
    const [propertySaveLoading, setPropertySaveLoading] = useState(false);
    const juhuu = useJUHUU();

    // name
    const [name, setName] = useState<string>("");
    const [legalName, setLegalName] = useState<string>("");

    //email
    const [email, setEmail] = useState<string>("");

    //phone
    const [phone, setPhone] = useState<string>("");

    //FaqUrl
    const [faqUrl, setFaqUrl] = useState<string>("");

    //website
    const [website, setWebsite] = useState<string>("");

    //contactUrl
    const [contactUrl, setcontactUrl] = useState<string>("");

    // billing address
    const [line1, setLine1] = useState<string>("");
    const [line2, setLine2] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [country, setCountry] = useState<CountryCode>("AT");

    // color scheme
    const [lightPrimary, setLightPrimary] = useState<string>(
        property?.colorScheme.light.primary || "#ffffff",
    );
    const [darkPrimary, setDarkPrimary] = useState<string>(
        property?.colorScheme.dark.primary || "#ffffff",
    );

    const handleUpdate = useCallback(async () => {
        if (property === null) {
            return;
        }

        setPropertySaveLoading(true);
        const query = await juhuu.properties.update({
            propertyId: property.id,
            name: name === "" ? undefined : name,
            legalName: legalName === "" ? undefined : legalName,
            email: email === "" ? undefined : email,
            phone: phone === null ? undefined : phone,
            faqUrl: faqUrl === "" ? undefined : faqUrl,
            website: website === "" ? undefined : website,
            contactUrl: contactUrl === "" ? undefined : contactUrl,
            billingAddress: {
                line1: line1 === "" ? undefined : line1,
                line2: line2 === "" ? undefined : line2,
                postalCode: postalCode === "" ? undefined : postalCode,
                city: city === "" ? undefined : city,
                country: country,
            },
            colorScheme: {
                light: {
                    primary: lightPrimary === "" ? undefined : lightPrimary,
                },
                dark: {
                    primary: darkPrimary === "" ? undefined : darkPrimary,
                },
            } as unknown as ColorScheme,
        });
        setPropertySaveLoading(false);

        console.log(darkPrimary);
        console.log(lightPrimary);

        if (query.ok === false) {
            return;
        }

        if (query.data.property.type !== "internal") {
            return;
        }

        setProperty(query.data.property);

        navigate(`/properties/${property?.id}`);
    }, [
        property,
        navigate,
        city,
        country,
        line1,
        line2,
        postalCode,
        setProperty,
        name,
        legalName,
        lightPrimary,
        darkPrimary,
        juhuu,
        email,
        phone,
        faqUrl,
        website,
        contactUrl,
    ]);

    const handleOpenStripe = useCallback(async () => {
        if (property === null) {
            return;
        }

        const query = await juhuu.properties.stripeAccountUrl({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        window.open(query.data.url, "_blank");
    }, [property, juhuu]);

    return (
        <>
            <Heading>Company settings</Heading>
            <Divider className="mb-4" />
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Name</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                The name of your company that will be visible to
                                your users.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={property?.name}
                                disabled={propertySaveLoading}
                                placeholder="JUHUU"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Legal name</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                The name of your company we use on the invoices
                                we create on your behalf.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={property?.legalName}
                                disabled={propertySaveLoading}
                                placeholder="JUHUU Mobility Ltd."
                                onChange={(event) =>
                                    setLegalName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>E-mail</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                This email will be displayed to everyone using
                                JUHUU. It can be used by your users to contact
                                you and will be displayed to everyone using
                                JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="email"
                                defaultValue={property?.email as string}
                                disabled={propertySaveLoading}
                                placeholder="office@juhuu.app"
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Phone number</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                This phone number will be displayed to everyone
                                using JUHUU. It can be used by your users to
                                contact you and will be displayed to everyone
                                using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={property?.phone as string}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setPhone(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Frequently asked questions</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                Provide a link to your own website or an{" "}
                                <TextLink href="./../articles">
                                    article
                                </TextLink>{" "}
                                where you list frequently asked questions. It
                                will be displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="url"
                                defaultValue={property?.faqUrl as string}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setFaqUrl(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Website</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                Provide a url to your website. It will be
                                displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="url"
                                defaultValue={property?.website as string}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setWebsite(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Contact Url</Label>
                            <Badge className="ml-2">Public</Badge>
                            <Description>
                                Provide a link to your own contact form that
                                your users can use to contact you. It will be
                                displayed to everyone using JUHUU.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="url"
                                defaultValue={property?.contactUrl as string}
                                disabled={propertySaveLoading}
                                placeholder=""
                                onChange={(event) =>
                                    setcontactUrl(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Billing information</Subheading>
            <Divider className="mb-4" />

            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Payment service provider setup</Label>
                            <Description>
                                JUHUU partners with Stripe for secure payments
                                and financial services. Open Stripe's settings
                                to setup the bank account you want to receive
                                your payouts to.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Button onClick={handleOpenStripe}>
                                Open Stripe's settings
                            </Button>
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Address line 1</Label>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={property?.billingAddress?.line1}
                                disabled={propertySaveLoading}
                                placeholder="Schrammelgasse 79"
                                onChange={(event) =>
                                    setLine1(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Address line 2</Label>
                        </div>
                        <div className="w-1/2">
                            <Input
                                defaultValue={
                                    property?.billingAddress?.line2 || ""
                                }
                                disabled={propertySaveLoading}
                                onChange={(event) =>
                                    setLine2(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Postal code</Label>
                        </div>
                        <div className="w-1/2">
                            <Input
                                placeholder="1170"
                                defaultValue={
                                    property?.billingAddress?.postalCode
                                }
                                disabled={propertySaveLoading}
                                onChange={(event) =>
                                    setPostalCode(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>City</Label>
                        </div>
                        <div className="w-1/2">
                            <Input
                                placeholder="Wien"
                                defaultValue={property?.billingAddress?.city}
                                disabled={propertySaveLoading}
                                onChange={(event) =>
                                    setCity(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Country</Label>
                            <Description>
                                If your country does not show up in the list,
                                please contact us.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Select
                                disabled={propertySaveLoading}
                                defaultValue={property?.billingAddress?.country}
                                onChange={(event) =>
                                    setCountry(
                                        event.target.value as CountryCode,
                                    )
                                }
                            >
                                <option value={"AT"}>Austria</option>
                                <option value={"DE"}>Germany</option>
                                <option value={"CH"}>Switzerland</option>
                                <option value={"LI"}>Liechtenstein</option>
                                <option value={"IT"}>Italy</option>
                                <option value={"FR"}>France</option>
                                <option value={"NL"}>Netherlands</option>
                                <option value={"BE"}>Belgium</option>
                                <option value={"LU"}>Luxembourg</option>
                                <option value={"DK"}>Denmark</option>
                                <option value={"SE"}>Sweden</option>
                                <option value={"NO"}>Norway</option>
                                <option value={"FI"}>Finland</option>
                                <option value={"IS"}>Iceland</option>
                                <option value={"GB"}>Great Britain</option>
                                <option value={"IE"}>Ireland</option>
                                <option value={"ES"}>Spain</option>
                                <option value={"PT"}>Portugal</option>
                                <option value={"GR"}>Greece</option>
                                <option value={"PL"}>Poland</option>
                                <option value={"CZ"}>Czech Republic</option>
                                <option value={"SK"}>Slovakia</option>
                                <option value={"HU"}>Hungary</option>
                                <option value={"SI"}>Slovenia</option>
                                <option value={"HR"}>Croatia</option>
                                <option value={"BA"}>
                                    Bosnia and Herzegovina
                                </option>
                                <option value={"RS"}>Serbia</option>
                                <option value={"US"}>United States</option>
                                <option value={"CA"}>Canada</option>
                            </Select>
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>

            <Subheading>Color scheme</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-8">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Primary light mode</Label>
                            <Description>
                                The primary color of your company that our
                                platform shows to your users if their device is
                                set to light mode.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <ColorPicker
                                color={lightPrimary}
                                setColor={setLightPrimary}
                                disabled={propertySaveLoading}
                            />
                        </div>
                    </Field>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2">
                            <Label>Primary dark mode</Label>
                            <Description>
                                The primary color of your company that our
                                platform shows to your users if their device is
                                set to dark mode.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <ColorPicker
                                color={darkPrimary}
                                setColor={setDarkPrimary}
                                disabled={propertySaveLoading}
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>

            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default PropertyEditRoute;
