import { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import Payment from "../payments/Payment";
import BadgeSessionStatus from "./BadgeSessionStatus";
import SessionType from "./SessionType";
import Timestamp from "../../formatters/Timestamp";
import User from "../users/User";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import {
    Alert,
    AlertActions,
    AlertDescription,
    AlertTitle,
} from "../../components/alert";

interface SessionRouteProps {}

const SessionRoute: React.FC<SessionRouteProps> = () => {
    const [session, setSession] = useState<JUHUU.Session.Object | null>(null);
    const { sessionId } = useParams<{ sessionId: string }>();
    const juhuu = useJUHUU();
    const navigate = useNavigate();

    const handleRefresh = useCallback(async () => {
        if (sessionId === undefined) {
            return;
        }

        const query = await juhuu.sessions.retrieve({
            sessionId: sessionId,
        });

        if (query.ok === false) {
            return;
        }

        setSession(query.data.session);
    }, [sessionId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const handleDeleteSession = useCallback(async () => {
        if (session === null) {
            return;
        }

        const query = await juhuu.sessions.delete({
            sessionId: session.id,
        });

        if (query.ok === false) {
            return;
        }

        navigate("./../");
    }, [session, juhuu, navigate]);

    const handleTerminateSession = useCallback(async () => {
        if (session === null) {
            return;
        }

        const query = await juhuu.sessions.terminate({
            sessionId: session.id,
            isOffSession: session.isOffSession,
        });

        if (query.ok === false) {
            return;
        }

        navigate("./../");
    }, [session, juhuu, navigate]);

    let [isOpenDelete, setIsOpenDelete] = useState(false);
    let [isOpenTerminate, setIsOpenTerminate] = useState(false);

    console.log(session?.status);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>
                        <SessionType type={session?.type} />
                    </Heading>

                    <BadgeSessionStatus status={session?.status} />
                </div>
                <div className="flex space-x-4">
                    <Button
                        color="red"
                        className="border-0"
                        onClick={() => setIsOpenDelete(true)}
                        disabled={
                            session?.status !== "completed" ||
                            session.paymentId !== null
                        }
                        disabledTooltip={
                            <>
                                <SessionType type={session?.type} />s can only
                                be deleted if they are free and completed.
                            </>
                        }
                    >
                        Delete
                    </Button>
                    {session?.status === "ready" && (
                        <Button
                            className="border-0"
                            onClick={() => setIsOpenTerminate(true)}
                        >
                            Terminate
                        </Button>
                    )}
                </div>
                <Alert open={isOpenDelete} onClose={setIsOpenDelete}>
                    <AlertTitle>
                        <SessionType type={session?.type} /> will be deleted
                    </AlertTitle>
                    <AlertDescription>
                        This action cannot be undone. Are you sure you want to
                        proceed?
                    </AlertDescription>
                    <AlertActions>
                        <Button plain onClick={() => setIsOpenDelete(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setIsOpenDelete(false);
                                handleDeleteSession();
                            }}
                            color="red"
                        >
                            Delete
                        </Button>
                    </AlertActions>
                </Alert>

                <Alert open={isOpenTerminate} onClose={setIsOpenTerminate}>
                    <AlertTitle>
                        <SessionType type={session?.type} /> will be deleted
                    </AlertTitle>
                    <AlertDescription>
                        This action cannot be undone. Are you sure you want to
                        proceed?
                    </AlertDescription>
                    <AlertActions>
                        <Button plain onClick={() => setIsOpenTerminate(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setIsOpenTerminate(false);
                                handleTerminateSession();
                            }}
                        >
                            Terminate
                        </Button>
                    </AlertActions>
                </Alert>
            </div>
            <Divider />
            <div className="flex justify-start flex-row items-center py-4">
                <div className="flex justfy-start flex-col items-start pr-8">
                    <Text>Created</Text>
                    <Text>
                        <Timestamp timestamp={session?.createdAt} />
                    </Text>
                </div>

                <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
                    <Text>User</Text>
                    <User userId={session?.userId} />
                </div>
            </div>
            <Payment
                paymentId={session?.paymentId}
                visible={session?.paymentId !== null}
            />
        </>
    );
};

export default SessionRoute;
