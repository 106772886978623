import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import LinkType from "./LinkType";
import { Button } from "../../components/button";
import QRCode from "react-qr-code";
import { useJUHUU } from "../../context/JuhuuContext";
import { useNavigate } from "react-router-dom";

interface LinkListRouteProps {}

const LinkListRoute: React.FC<LinkListRouteProps> = () => {
    const [linkArray, setLinkArray] = useState<JUHUU.Link.Object[]>([]);
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const navigate = useNavigate();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.links.list({
            propertyId: property.id,
        });

        if (query.ok === false) {
            return;
        }
        setLinkArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const createLink = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.links.create({
            propertyId: property?.id,
            name: property?.name,
            type: "fiveLetterQr",
        });

        if (query.ok === false) {
            return;
        }

        navigate("./" + query.data.link.id);
    }, [property, juhuu, navigate]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>QR-Codes</Heading>
                <Button onClick={() => createLink()}>Create</Button>
            </div>
            <Text className="mb-8">
                This list contains your QR-codes that link to locations. If a
                users scans a QR-code with the JUHUU app they are being
                forwarded to one of your locations. Not every location must have
                a QR-code, you decide which locations should have one.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Type</TableHeader>
                        <TableHeader>Forwards to</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {linkArray.map((link) => {
                        return (
                            <TableRow
                                key={link.id}
                                // href={"./" + link.id}
                                style={{
                                    maxHeight: "20px",
                                }}
                            >
                                <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                                    <QRCode
                                        value={
                                            "https://juhuu.app/qr/fiveLetterQr/" +
                                            link.fiveLetterQr
                                        }
                                        style={{
                                            height: "auto",
                                            maxWidth: "50px",
                                            width: "50px",
                                        }}
                                    />

                                    <Text>{link.name}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>
                                        <LinkType type={link.type} />
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        href={
                                            "./../locations/" +
                                            link.referenceObjectId
                                        }
                                    >
                                        Go to location
                                    </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default LinkListRoute;
