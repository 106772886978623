import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import BadgeSimStatus from "./BadgeSimStatus";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";

interface SimListRouteProps {}

const SimListRoute: React.FC<SimListRouteProps> = () => {
    const [simArray, setSimArray] = useState<JUHUU.Sim.Object[]>([]);
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const navigate = useNavigate();

    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.sims.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setSimArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const createSim = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.sims.create({
            propertyId: property?.id,
            name: property?.name,
        });

        if (query.ok === false) {
            return;
        }

        navigate("./" + query.data.sim.id);
    }, [property, juhuu]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Sim Cards</Heading>
                <Button onClick={() => createSim()}>Create</Button>
            </div>
            <Text className="mb-8">
                Add a SIM and its provider to be notified if quota is used up
                and to see it's status.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Quota</TableHeader>
                        <TableHeader>Provider</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {simArray.map((sim) => {
                        let quota: string = "-";
                        if (
                            sim.dataQuotaCurrent !== null &&
                            sim.dataQuotaMax !== null
                        ) {
                            quota =
                                (sim.dataQuotaCurrent / sim.dataQuotaMax) *
                                    100 +
                                " %";
                        }

                        return (
                            <TableRow key={sim.id} href={"./" + sim.id}>
                                <TableCell>{sim.name} </TableCell>
                                <TableCell>
                                    <BadgeSimStatus status={sim.status} />
                                </TableCell>
                                <TableCell>{quota}</TableCell>
                                <TableCell>{sim.provider} </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default SimListRoute;
