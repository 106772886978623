import { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Divider } from "../../components/divider";
import { useParams } from "react-router-dom";

interface TermRouteProps {}

const TermRoute: React.FC<TermRouteProps> = () => {
    const juhuu = useJUHUU();
    const [term, setTerm] = useState<JUHUU.Term.Object>();
    const { termId } = useParams<{ termId: string }>();

    const handleRefresh = useCallback(async () => {
        if (termId === undefined) {
            return;
        }

        const query = await juhuu.terms.retrieve({
            termId: termId,
        });

        if (query.ok === false) {
            return;
        }

        setTerm(query.data.term);
    }, [termId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>{term?.name}</Heading>
                </div>

                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-1">
                        <Text>{term?.id}</Text>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="mt-4 ">
                <div className="my-2">
                    <Text>DSGVO URL</Text>
                    <Text>{term?.dsgvoUrl}</Text>
                </div>
                <div className="my-2">
                    <Text>Privacy URL</Text>
                    <Text>{term?.url}</Text>
                </div>
            </div>
        </>
    );
};

export default TermRoute;
/*
amountAuthorized: number; // amount of the article that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the article that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
