import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";

interface AccountingAreaListRouteProps {}

const AccountingAreaListRoute: React.FC<AccountingAreaListRouteProps> = () => {
    const [accountingAreaArray, setAccountingAreaArray] = useState<
        JUHUU.AccountingArea.Object[]
    >([]);
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const navigate = useNavigate();

    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.accountingAreas.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setAccountingAreaArray(query.data);
    }, [property, juhuu]);

    const createAccountingArea = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.accountingAreas.create({
            propertyId: property?.id,
            name: property?.name,
        });

        if (query.ok === false) {
            return;
        }
        navigate("./" + query.data.accountingArea.id);
    }, [property, juhuu, navigate]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Accounting Areas</Heading>
                <Button onClick={createAccountingArea}>Create</Button>
            </div>

            <Text className="mb-8 mt-4">
                Setup accountingAreas to group your payments into regions. Once
                a payout is created, payments are grouped by accountingAreas on
                the credit note. This might be useful for accounting purposes.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accountingAreaArray.map((accountingArea) => {
                        return (
                            <TableRow
                                key={accountingArea.id}
                                href={"./" + accountingArea.id}
                            >
                                <TableCell>{accountingArea.name} </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default AccountingAreaListRoute;
