import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Badge } from "../../components/badge";
import { Text } from "../../components/text";
import Amount from "../../formatters/Amount";
import BadgeStatusPayment from "./BadgeStatusPayment";
import Timestamp from "../../formatters/Timestamp";
import { useJUHUU } from "../../context/JuhuuContext";

interface PaymentListRouteProps {}

const PaymentListRoute: React.FC<PaymentListRouteProps> = () => {
    const [paymentArray, setPaymentArray] = useState<JUHUU.Payment.Object[]>(
        [],
    );
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }
        const query = await juhuu.payments.list({
            propertyId: property?.id,
        });
        if (query.ok === false) {
            return;
        }
        setPaymentArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Payments of your users</Heading>
            <Text className="mb-8">
                This list contains all payments of your users.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Amount</TableHeader>
                        <TableHeader>Created</TableHeader>
                        <TableHeader>Status</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paymentArray.map((payment) => {
                        const date = new Date(payment.createdAt);
                        const isRecent =
                            date.getTime() >
                            Date.now() - 1000 * 60 * 60 * 24 * 7;

                        return (
                            <TableRow key={payment.id} href={"./" + payment.id}>
                                <TableCell>
                                    <Amount
                                        amount={
                                            payment.amountToPayout ??
                                            payment.amountFinal ??
                                            payment.amountAuthorized
                                        }
                                        currencyCode={payment.currencyCode}
                                    />
                                </TableCell>

                                <TableCell>
                                    <Timestamp timestamp={payment.createdAt} />
                                    {isRecent === true && (
                                        <Badge color="lime" className="ml-2">
                                            New
                                        </Badge>
                                    )}
                                </TableCell>

                                <TableCell>
                                    <BadgeStatusPayment
                                        status={payment.status}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default PaymentListRoute;
