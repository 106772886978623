import React, { useCallback, useState } from "react";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import DeviceArray from "./DeviceArray";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";
import { Field, Label } from "../../components/fieldset";
import { Listbox, ListboxLabel, ListboxOption } from "../../components/listbox";
import { JUHUU } from "@juhuu/sdk-ts";
import { useNavigate } from "react-router-dom";

interface DeviceListRouteProps {}

const DeviceListRoute: React.FC<DeviceListRouteProps> = () => {
    const [deviceTemplateArray, setDeviceTemplateArray] = useState<
        JUHUU.DeviceTemplate.Object[]
    >([]);
    const { property } = useProperty();
    const navigate = useNavigate();
    const juhuu = useJUHUU();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDeviceTemplateId, setSelectedDeviceTemplateId] =
        useState("");

    const getDevicesTemplates = useCallback(async () => {
        if (!property?.id) {
            console.error("Property ID is missing.");
            return;
        }
        const query = await juhuu.deviceTemplates.list({
            propertyId: property.id,
        });

        if (!query.ok) {
            console.error("Failed to fetch device templates.");
            return;
        }

        setDeviceTemplateArray(query.data);
    }, [property, juhuu]);

    const createDevice = useCallback(
        async (deviceTemplateId: string) => {
            if (!property?.id) {
                console.error("Property ID is missing.");
                return;
            }
            if (!deviceTemplateId) {
                console.error("Device Template ID is missing.");
                return;
            }

            const query = await juhuu.devices.create({
                propertyId: property.id,
                name: property.name,
                deviceTemplateId,
            });

            if (!query.ok) {
                console.error("Failed to create device:", query.data);
                return;
            }

            console.log("Device created successfully:", query.data);
            navigate("./" + query.data.device.id);
        },
        [property, juhuu, navigate],
    );

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Devices</Heading>
                <Button
                    onClick={() => {
                        getDevicesTemplates();
                        setIsOpen(true);
                    }}
                >
                    Create
                </Button>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Create Device</DialogTitle>
                <DialogDescription>Dialog Description</DialogDescription>
                <DialogBody>
                    <Field>
                        <Label>Device Template Id?</Label>
                        <Listbox
                            name="id"
                            value={selectedDeviceTemplateId}
                            onChange={(value) => {
                                setSelectedDeviceTemplateId(value);
                            }}
                        >
                            {deviceTemplateArray.map((deviceTemplate) => (
                                <ListboxOption
                                    key={deviceTemplate.id}
                                    value={deviceTemplate.id}
                                >
                                    <ListboxLabel>
                                        {deviceTemplate.name}
                                    </ListboxLabel>
                                </ListboxOption>
                            ))}
                        </Listbox>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button
                        plain
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            createDevice(selectedDeviceTemplateId); // Pass the ID directly
                        }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Text className="mb-8">
                This list contains the devices your users can use.
            </Text>
            <DeviceArray
                deviceListParams={{
                    propertyId: property?.id,
                }}
            />
        </>
    );
};

export default DeviceListRoute;
