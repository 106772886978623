import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text, TextLink } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";

interface ProductListRouteProps {}

const ProductListRoute: React.FC<ProductListRouteProps> = () => {
    const [productArray, setProductArray] = useState<JUHUU.Product.Object[]>(
        [],
    );
    const juhuu = useJUHUU();
    const navigate = useNavigate();
    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.products.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setProductArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const createProduct = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.products.create({
            propertyId: property?.id,
            name: property?.name,
        });

        if (query.ok === false) {
            return;
        }
        navigate("./" + query.data.product.id);
    }, [property, juhuu, navigate]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Products</Heading>
                <Button onClick={createProduct}>Create</Button>
            </div>
            <Text className="mb-8">
                Products you add to this list will appear on the{" "}
                <TextLink
                    href="https://juhuu.app/marketplace"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    JUHUU Marketplace
                </TextLink>
                .
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productArray.map((product) => {
                        return (
                            <TableRow key={product.id} href={"./" + product.id}>
                                <TableCell>{product.name} </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default ProductListRoute;
