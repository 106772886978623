import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";

interface TermListRouteProps {}

const TermListRoute: React.FC<TermListRouteProps> = () => {
    const [termArray, setTermArray] = useState<JUHUU.Term.Object[]>([]);
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const navigate = useNavigate();

    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.terms.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setTermArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const createTerm = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.terms.create({
            propertyId: property?.id,
            name: property?.name,
        });

        if (query.ok === false) {
            return;
        }
        navigate("./" + query.data.term.id);
    }, [property, juhuu, navigate]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4">
                <Heading>Terms</Heading>
                <Button onClick={createTerm}>Create</Button>
            </div>
            <Text className="mb-8">
                Setup terms that your users need to accept before they can
                create a rental or reservation.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {termArray.map((term) => {
                        return (
                            <TableRow key={term.id} href={"./" + term.id}>
                                <TableCell>{term.name} </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default TermListRoute;
