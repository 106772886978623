import {
    EllipsisHorizontalIcon,
    HandThumbDownIcon,
    HandThumbUpIcon,
} from "@heroicons/react/16/solid";
import Timestamp from "../../formatters/Timestamp";
import { Text } from "../../components/text";
import Markdown from "../../components/markdown";
import { useCallback, useMemo, useState } from "react";
import { useJUHUU } from "../../context/JuhuuContext";
import { JUHUU } from "@juhuu/sdk-ts";
import clsx from "clsx";

const ChatMessage: React.FC<{
    chatMessage: JUHUU.ChatMessage.Object;
    setChatMessage: (chatMessage: JUHUU.ChatMessage.Object) => void;
}> = ({ chatMessage, setChatMessage }) => {
    const juhuu = useJUHUU();
    const [showContext, setShowContext] = useState(false);

    const articleIdArray = useMemo(() => {
        if (chatMessage?.context?.articleIdArray === undefined) {
            return [];
        }

        return chatMessage.context.articleIdArray;
    }, [chatMessage]);

    const handleRating = useCallback(
        async (rating: JUHUU.ChatMessage.AiChatMessage["rating"]) => {
            if (chatMessage === null) {
                return;
            }

            const query = await juhuu.chatMessages.update({
                chatMessageId: chatMessage.id,
                rating: rating,
            });

            if (query.ok === false) {
                return;
            }

            setChatMessage(query.data.chatMessage);
        },
        [chatMessage, juhuu, setChatMessage],
    );

    return (
        <div
            key={chatMessage.id}
            className={clsx(
                "w-full flex flex-row items-start",
                chatMessage.type === "ai" ? "justify-start" : "justify-end",
            )}
        >
            <div
                className={clsx(
                    "width-1/2 p-2 rounded-2xl mb-2",
                    chatMessage.type === "ai" ? "bg-zinc-200" : "bg-lime-400",
                )}
            >
                <Markdown
                    markdown={
                        chatMessage.type === "ai"
                            ? chatMessage.translatedMessage
                            : chatMessage.originalMessage
                    }
                />
                {articleIdArray.length > 0 && (
                    <>
                        <Text className="mt-2">
                            JUHUU Buddy used the following sources:
                        </Text>
                        <div className="flex flex-row flex-wrap gap-2">
                            {articleIdArray.map((articleId, index) => {
                                if (index > 2) {
                                    return null;
                                }

                                return (
                                    <div
                                        className="h-12 w-44 border-2 border-zinc-300 bg-white rounded-lg py-1 px-2 hover:bg-zinc-100 cursor-pointer"
                                        onClick={() =>
                                            window.open(
                                                "https://docs.juhuu.app/articles/" +
                                                    articleId,
                                                "_blank",
                                            )
                                        }
                                    >
                                        <Text
                                            style={{
                                                color: "#000",
                                                fontSize: "0.75rem",
                                            }}
                                        >
                                            Source {index + 1}
                                        </Text>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}

                <div className="flex flex-row justify-start items-center gap-2 mt-2">
                    <Text
                        style={{
                            fontSize: "0.75rem",
                        }}
                        className={clsx(
                            chatMessage.type === "ai"
                                ? "text-left"
                                : "text-right",
                        )}
                    >
                        <Timestamp
                            timestamp={chatMessage.createdAt}
                            display="minutes"
                        />
                    </Text>
                    {chatMessage.type === "ai" &&
                        chatMessage.rating === null && (
                            <>
                                <HandThumbUpIcon
                                    className="h-4 w-4 cursor-pointer text-zinc-600"
                                    onClick={() => handleRating("good")}
                                />
                                <HandThumbDownIcon
                                    className="h-4 w-4 cursor-pointer text-zinc-600"
                                    onClick={() => handleRating("bad")}
                                />
                            </>
                        )}
                    <EllipsisHorizontalIcon
                        className="ml-auto h-4 w-4 cursor-pointer text-zinc-600"
                        onClick={() => setShowContext(!showContext)}
                    />
                </div>
                {showContext === true && (
                    <div>
                        {chatMessage.context?.userName !== undefined && (
                            <Text
                                style={{
                                    fontSize: "0.75rem",
                                }}
                            >
                                User name: {chatMessage.context.userName}
                            </Text>
                        )}
                        {chatMessage.context?.userGroup !== undefined && (
                            <Text
                                style={{
                                    fontSize: "0.75rem",
                                }}
                            >
                                User group: {chatMessage.context.userGroup}
                            </Text>
                        )}
                        {chatMessage.context?.ownerPropertyId !== undefined && (
                            <Text
                                style={{
                                    fontSize: "0.75rem",
                                }}
                            >
                                Owner Property ID:{" "}
                                {chatMessage.context.ownerPropertyId}
                            </Text>
                        )}
                        {chatMessage.context?.frontend !== undefined && (
                            <Text
                                style={{
                                    fontSize: "0.75rem",
                                }}
                            >
                                Frontend: {chatMessage.context.frontend}
                            </Text>
                        )}
                        {chatMessage.context?.platform !== undefined && (
                            <Text
                                style={{
                                    fontSize: "0.75rem",
                                }}
                            >
                                Platform: {chatMessage.context.platform}
                            </Text>
                        )}

                        <Text
                            style={{
                                fontSize: "0.75rem",
                            }}
                        >
                            ID: {chatMessage.id}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatMessage;
