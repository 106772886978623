import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Text } from "../../components/text";
import Timestamp from "../../formatters/Timestamp";
import { useJUHUU } from "../../context/JuhuuContext";
import buddy from "./buddy.png";
import { Heading } from "../../components/heading";

interface ChatArrayProps {
    chatListParams?: JUHUU.Chat.List.Params;
    chatIdArray?: string[];
    onSelectedChat?: (chat: JUHUU.Chat.Object) => void;
}

const stepSize = 900;
const pageGapThreshold = 8;

const ChatArray: React.FC<ChatArrayProps> = ({
    chatListParams,
    chatIdArray,
    onSelectedChat,
}) => {
    const [chatArray, setChatArray] = useState<JUHUU.Chat.Object[]>([]);
    const [currentPageNumber, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);

    const juhuu = useJUHUU();

    const maxPages = useMemo(() => {
        return Math.ceil(count / stepSize);
    }, [count]);

    const handleRefresh = useCallback(async () => {
        if (chatListParams !== undefined) {
            const query = await juhuu.chats.list(chatListParams, {
                limit: stepSize,
                skip: (currentPageNumber - 1) * stepSize,
            });

            if (query.ok === false) {
                return;
            }

            setChatArray(query.data.chatArray);
            setCount(query.data.count);
        } else if (chatIdArray !== undefined) {
            console.log("chatIdArray", chatIdArray);
            // fetch chats by id
            const queryPromiseArray: Promise<
                JUHUU.HttpResponse<JUHUU.Chat.Retrieve.Response>
            >[] = [];

            chatIdArray.forEach(async (chatId) => {
                queryPromiseArray.push(
                    juhuu.chats.retrieve({
                        chatId: chatId,
                    }),
                );
            });

            setCount(chatIdArray.length);

            const queryArray = await Promise.all(queryPromiseArray);

            const tempChatArray: JUHUU.Chat.Object[] = [];

            queryArray.forEach((query) => {
                if (query.ok === true) {
                    tempChatArray.push(query.data.chat);
                }
            });

            setChatArray(tempChatArray);
        }
    }, [chatListParams, chatIdArray, currentPageNumber, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <div className="w-full flex flex-col gap-2">
                {chatArray.length <= 2 && (
                    <div>
                        <img src={buddy} alt="JUHUU Buddy" />
                        <Text className="text-center p-4">
                            JUHUU Buddy is designed to be your personal
                            assistant. He can perform tasks on your behalf, such
                            as creating a new location or tariff that shows up
                            in the JUHUU app. Just ask him "Hey Buddy, create a
                            new location with the name 'Townhall' for me".
                            Please note that this is a beta feature.
                        </Text>
                    </div>
                )}
                {chatArray.map((chat) => {
                    return (
                        <div
                            key={chat.id}
                            className={
                                "w-full p-2 bg-white border border-zinc-600 rounded-xl h-16 w-full cursor-pointer flex flex-col justify-between"
                            }
                            onClick={() => {
                                if (onSelectedChat !== undefined) {
                                    onSelectedChat(chat);
                                }
                            }}
                        >
                            <Text className="truncate">
                                {chat.title ?? "New Chat"}
                            </Text>
                            <Timestamp
                                timestamp={new Date(chat?.lastMessageAt ?? "")}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ChatArray;
