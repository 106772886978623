import React, { useCallback } from "react";
import { Button } from "./button";

interface ImagePickerProps {
    setImage: (image: string | null) => void;
    image: string | null;
    disabled?: boolean;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
    setImage,
    image,
    disabled = false,
}) => {
    const handleOpenImageImport = useCallback(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/png";
        input.onchange = async (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (target.files && target.files[0]) {
                const file = target.files[0];
                const reader = new FileReader();

                reader.onload = () => {
                    if (reader.result) {
                        setImage(reader.result as string);
                    }
                };

                reader.readAsDataURL(file);
            }
        };
        input.click();
    }, [setImage]);

    return (
        <>
            <div className="flex flex-col gap-4 mt-2 w-full justify-start items-end">
                <div className="w-full h-32 border rounded-lg flex justify-center items-center overflow-hidden">
                    {image === null ? (
                        <Button
                            onClick={handleOpenImageImport}
                            disabled={disabled}
                        >
                            Select image
                        </Button>
                    ) : (
                        <img
                            src={image}
                            alt="img to upload"
                            className="w-full h-full object-contain"
                        />
                    )}
                </div>

                {image !== null && (
                    <div className="flex flex-row gap-4">
                        <Button
                            onClick={() => setImage(null)}
                            plain
                            disabled={disabled}
                        >
                            Remove image
                        </Button>
                        <Button
                            onClick={handleOpenImageImport}
                            disabled={disabled}
                        >
                            Change image
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

export default ImagePicker;
